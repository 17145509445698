import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CgMenuGridO } from "react-icons/cg";
import React, { useState } from "react";
import { useCart } from "react-use-cart";

const Navbar = () => {
  const { totalUniqueItems } = useCart();
  const [dropDown, setdropDown] = useState(false);

  return (
    <>
      <div className="hidden md:flex items-center justify-center pb-2 shadow-lg">
        <nav className="text-black uppercase flex items-center justify-around gap-28 text-xs tracking-widest font-semibold p-1">
          <Link
            className="w-24 hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/about-us"
          >
            About us
          </Link>
          <Link
            className="w-24 hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/all-products"
          >
            Products
          </Link>
          <div className="w-16">
            <Link to="/">
              <img src={logo} alt="" className="w-40 p-0" />
            </Link>
          </div>
          <Link
            className="w-24 hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/contact-us"
          >
            Contact us
          </Link>
          <Link
            className="w-24 hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/cart"
          >
            <AiOutlineShoppingCart className="inline-block text-xl mt-[-5px]" />
            <span className="inline-block p-2">Cart {totalUniqueItems}</span>
          </Link>
        </nav>
      </div>
      {/* Mobile sidebar */}
      <div className="flex md:hidden items-center justify-between pb-2 shadow-lg">
        <nav className="text-black uppercase flex items-center justify-between  text-xs tracking-widest font-semibold p-1 w-full">
          <div className="w-16">
            <Link to="/">
              <img src={logo} alt="" className="w-40 p-0 pl-4" />
            </Link>
          </div>
          <Link
            onClick={() => setdropDown(!dropDown)}
            className="hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2 pr-4 text-4xl"
            to="#"
          >
            <CgMenuGridO />
          </Link>
        </nav>
      </div>
      <div
        className={`w-full dropdown-div  overflow-hidden ${
          dropDown ? "p-5 h-auto" : "p-0 h-0"
        }  transition-all duration-200 bg-white md:hidden`}
      >
        {/* <h3 className="font-semibold text-2xl mt-4">Browse our website</h3> */}
        <h4 className="font-semibold text-xl my-6 mt-6">
          <Link
            onClick={() => setdropDown(false)}
            className="w-full hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/"
          >
            Home
          </Link>
        </h4>
        <h4 className="font-semibold text-xl my-6 mt-6">
          <Link
            onClick={() => setdropDown(false)}
            className="w-full hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/about-us"
          >
            About us
          </Link>
        </h4>
        <h4 className="font-semibold text-xl my-6">
          <Link
            onClick={() => setdropDown(false)}
            className="w-full hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/all-products"
          >
            Products
          </Link>
        </h4>
        <h4 className="font-semibold text-xl my-6">
          <Link
            onClick={() => setdropDown(false)}
            className="w-full hover:opacity-50 transition-all duration-200 active:text-yellow-600 pt-2"
            to="/contact-us"
          >
            Contact us
          </Link>
        </h4>
        <h4 className="font-semibold text-xl my-6">
          <Link
            onClick={() => setdropDown(false)}
            className=" bg-slate-900 text-white rounded-md hover:opacity-50 transition-all duration-200 active:text-yellow-600  p-1 inline-block ml-auto"
            to="/cart"
          >
            <AiOutlineShoppingCart className="inline-block text-xl mt-[-5px]" />
            <span className="inline-block p-2">
              Shopping Cart: {totalUniqueItems}
            </span>
          </Link>
        </h4>
      </div>
    </>
  );
};

export default Navbar;
