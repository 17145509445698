import React from "react";
import footerLogo from "../images/footerlogo.png";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <section className="bg-black p-14 md:py-28 md:p-0 text-white relative bottom-0 left-0">
        <div className="mx-auto w-full md:w-[75%] md:flex md:gap-28">
          <div className="outer-left-d w-full md:w-[55%] md:flex md:gap-14 ">
            <div className="w-[50%] mx-auto md:w-[20%] md:mx-0">
              <img
                src={footerLogo}
                className="aspect-square"
                alt="Nouveau Riche Logo"
              />
            </div>
            <div className="w-full mt-4 mb-10 md:w-[80%] md:mb-0 md:mt-0">
              <h3 className="font-bold text-xl pb-5">OUR MISSION</h3>
              <p className="text-sm font-semibold">
                Our Mission is to advance total body awakening through the
                promotion of a sound mind, spiritual consciousness and renewed
                health
              </p>
            </div>
          </div>

          <div className="outer-right-d w-full md:w-[45%] md:flex md:gap-24">
            <div className="w-full md:w-[35%]">
              <h3 className="font-bold text-xl pb-5">QUICK LINKS</h3>
              <p className="text-sm mb-2 uppercase">
                <Link to="/all-products">Products</Link>
              </p>
              <p className="text-sm uppercase">
                <Link to="/contact-us">Contact us</Link>
              </p>
              <p className="pt-10 w-full flex gap-5 text-xl">
                <a
                  style={{
                    border: "2px solid transparent",
                    borderRadius: "50%",
                    backgroundImage:
                      "linear-gradient(black, black), radial-gradient(circle at bottom right, #F5D589 ,transparent 95%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "padding-box, border-box",
                  }}
                  href="https://instagram.com/nouveaurichegh_?igshid=YmMyMTA2M2Y="
                  className="inline-block p-2"
                >
                  <FaInstagram />
                </a>
                <a
                  style={{
                    border: "2px solid transparent",
                    borderRadius: "50%",
                    backgroundImage:
                      "linear-gradient(black, black), radial-gradient(circle at bottom right, #F5D589 ,transparent 95%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "padding-box, border-box",
                  }}
                  href="https://twitter.com/nouveaurichegh?s=11&t=5K-H94PL-1Zgr6DIqHTlpg"
                  className="inline-block p-2"
                >
                  <FaTwitter />
                </a>
                <a
                  style={{
                    border: "2px solid transparent",
                    borderRadius: "50%",
                    backgroundImage:
                      "linear-gradient(black, black), radial-gradient(circle at bottom right, #F5D589 ,transparent 95%)",
                    backgroundOrigin: "border-box",
                    backgroundClip: "padding-box, border-box",
                  }}
                  href="https://www.facebook.com/Nouveaurichegh?mibextid=LQQJ4d"
                  className="inline-block p-2"
                >
                  <FaFacebookF />
                </a>
              </p>
            </div>
            <div className="w-full mt-10 md:w-[65%] md:mt-0 ">
              <h3 className="font-bold text-xl pb-5">LOCATION</h3>
              <p className="text-sm text-gray-400 mb-2 font-semibold">
                Shop #16, 10 Boundary Road
              </p>
              <p className="text-sm text-gray-400 mb-2 font-semibold">
                Shiashe, East Legon
              </p>
              <p className="text-sm text-gray-400 mb-2 font-semibold">
                Accra, Ghana
              </p>
              <hr className="my-4" />
              <h3 className="font-bold text-xl pb-2">PHONE</h3>
              <p className="text-sm text-gray-400 mb-2 font-semibold">
                Telephone: 0302529837
              </p>
              <p className="text-sm text-gray-400 mb-2 font-semibold">
                Mobile: 0598613855
              </p>
            </div>
          </div>
          {/**end right outer div */}
        </div>
      </section>
      <div className="mx-auto relative bottom-0 left-0 text-white bg-zinc-900 p-3">
        <p className="text-center">All Rights Reserved</p>
      </div>
    </>
  );
};

export default Footer;
