export function hasDiscount(product) {
  return product.discount_status === 1;
}

export function discountValue(product) {
  if (product.discount_type === "percentage") {
    let percentage_value = parseFloat(product.discount_value, 2);
    return percentage_value.toLocaleString() + "%";
  } else {
    return "GH₵" + parseFloat(product.discount_value, 2);
  }
}

export function discountedPriceValue(product) {
  if (product.discount_type === "percentage") {
    let dsc_value =
      product.unit_selling_price_without_currency -
      (product.discount_value / 100) *
        product.unit_selling_price_without_currency;
    return dsc_value;
  } else {
    return product.unit_selling_price_without_currency - product.discount_value;
  }
}
