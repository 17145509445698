import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import SharedLayout from "./layout/SharedLayout";
import Aboutus from "./pages/Aboutus";
import Checkout from "./pages/Checkout";
import Cart from "./pages/Cart";
import Contactus from "./pages/Contactus";
import Home from "./pages/Home";
import AllProducts from "./pages/AllProducts";
import ProductDetail from "./pages/ProductDetail";
import { CartProvider } from "react-use-cart";
import toast, { Toaster } from "react-hot-toast";

function App() {
  return (
    <CartProvider
      onItemAdd={(item) => toast.success(`${item.product_name} added!`)}
      onItemUpdate={(item) => toast.success(`${item.product_name} updated!`)}
      onItemRemove={() => toast.success(`Product removed from cart!`)}
    >
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<Home />} />
            <Route path="about-us" element={<Aboutus />} />
            <Route path="contact-us" element={<Contactus />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="cart" element={<Cart />} />
            <Route path="all-products" element={<AllProducts />} />
            <Route
              path="product-detail/:productId"
              element={<ProductDetail />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </CartProvider>
  );
}

export default App;
