import React from "react";
import { CgChevronDown } from "react-icons/cg";
import { Link } from "react-router-dom";
import MainCTAButton from "../components/MainCTAButton";
import PageHeading from "../components/PageHeading";
import SingleProduct from "../components/SingleProduct";
// import p1 from "../images/p1.jpg";
// import p2 from "../images/p2.jpg";
// import p3 from "../images/p3.jpg";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { InfinitySpin } from "react-loader-spinner";

const AllProducts = () => {
  // const images = [
  //   p1,
  //   p2,
  //   p3,
  //   p1,
  //   p1,
  //   p2,
  //   p3,
  //   p1,
  //   p1,
  //   p2,
  //   p3,
  //   p1,
  //   p1,
  //   p2,
  //   p3,
  //   p1,
  // ];

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterButtonText, setfilterButtonText] = useState("Apply Filter");
  const [responsiveFilterOpen, setresponsiveFilterOpen] = useState(true);

  let [searchParams] = useSearchParams();
  let child_category_from_url = searchParams.get("child_category");

  const [filter, setFilter] = useState({
    searchTerm: "",
    priceMin: "",
    priceMax: "",
    mainCategory: "",
    subCategory: "",
    childCategory: child_category_from_url ?? "",
  });

  function applyFilter() {
    setfilterButtonText("Filtering...");

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/filter-products-paginated?business_secrete=${process.env.REACT_APP_BUSINESS_SECRETE}&per_page=200&product_name=${filter.searchTerm}&category=${filter.mainCategory}&sub_category=${filter.subCategory}&child_category=${filter.childCategory}&minimum_price=${filter.priceMin}&maximum_price=${filter.priceMax}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((data) => {
        setProducts(data.data.products);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setfilterButtonText("Apply Filter");
        setresponsiveFilterOpen(false);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    //console.log(filter);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    //get products

    if (child_category_from_url) {
      applyFilter();
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/get-unpaginated-products-list?business_secrete=${process.env.REACT_APP_BUSINESS_SECRETE}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
            },
          }
        )
        .then((data) => {
          setProducts(data.data.products);
          // setLoading(false);
        })
        .catch((error) => console.log(error));
    }
    //get categories
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/get-all-ecommerce-product-categories?business_secrete=${process.env.REACT_APP_BUSINESS_SECRETE}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((data) => {
        setCategories(data.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <>
        <PageHeading title="Loading products..." />
        <div className="all-products-div flex gap-20 w-4/5 mx-auto mb-96 h-[20rem] justify-center items-center">
          <InfinitySpin width="200" color="#000" />
        </div>
      </>
    );
  } else {
    return (
      <>
        <PageHeading title="Products" />
        <div className="all-products-div md:flex gap-10 w-4/5 mx-auto mb-20">
          <h1
            className="w-full pt-4 md:hidden"
            onClick={() => setresponsiveFilterOpen(!responsiveFilterOpen)}
          >
            <MainCTAButton>
              {responsiveFilterOpen ? "Close Filter" : "Open Filter"}
            </MainCTAButton>
          </h1>
          <div
            className={`filter-div w-full ${
              responsiveFilterOpen ? "block" : "hidden"
            } md:w-[20%]`}
          >
            {/* <div
            className={`filter-div w-full ${
              responsiveFilterOpen ? "block" : "hidden"
            } md:w-[20%]`}
          > */}
            <h1 className="font-bold text-xl hidden md:block">Filters</h1>

            <h1 className="font-bold pt-5 text-xl mb-3">Product Types</h1>
            <p className="font-semibold cursor-pointer mb-2">
              <label
                htmlFor="show_all_product_types"
                className="cursor-pointer"
              >
                <input
                  type="radio"
                  id="show_all_product_types"
                  name="product_types"
                  className="mr-2"
                  value="show all"
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      childCategory: "",
                      mainCategory: e.target.value,
                    })
                  }
                />
                Show all
              </label>
            </p>
            {categories.ecommerce_product_categories.map((mc) => {
              return (
                mc.ecommerce_category_name !== "General" && (
                  <p className="font-semibold cursor-pointer mb-2" key={mc.id}>
                    <label
                      htmlFor={`product_types_${mc.id}`}
                      className="cursor-pointer dummy"
                    >
                      <input
                        type="radio"
                        id={`product_types_${mc.id}`}
                        name="product_types"
                        className="mr-2"
                        value={mc.id}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            childCategory: "",
                            mainCategory: e.target.value,
                          })
                        }
                      />
                      {mc.ecommerce_category_name}
                    </label>
                  </p>
                )
              );
            })}

            {/* <h1 className="font-bold pt-5 text-xl pb-2 mb-3">Targeted Areas</h1>
            <p className="font-semibold cursor-pointer mb-2">
              <label
                htmlFor="show_all_targetted_areas"
                className="cursor-pointer"
              >
                <input
                  type="radio"
                  id="show_all_targetted_areas"
                  name="targetted_areas"
                  className="mr-2"
                  value="show all"
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      childCategory: "",
                      subCategory: e.target.value,
                    })
                  }
                />
                Show all
              </label>
            </p>
            {categories.sub_categories.map((sc) => {
              return (
                <p className="font-semibold cursor-pointer mb-2" key={sc.id}>
                  <label
                    htmlFor={`targetted_areas_${sc.id}`}
                    className="cursor-pointer"
                  >
                    <input
                      type="radio"
                      id={`targetted_areas_${sc.id}`}
                      name="targetted_areas"
                      className="mr-2"
                      value={sc.id}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          childCategory: "",
                          subCategory: e.target.value,
                        })
                      }
                    />
                    {sc.sub_category_name}
                  </label>
                </p>
              );
            })} */}

            <h1 className="font-bold pt-5 text-xl mb-3">Price</h1>
            <p className="w-full">
              <input
                type="number"
                className="bg-white text-black text-sm 
                    w-[40%]
                    placeholder-black
                    border-b-4
                    border-b-black
                    outline-none
                    font-bold 
                     p-2.5 
                    transition-all duration-200
                    focus:outline-none
                    "
                onChange={(e) =>
                  setFilter({ ...filter, priceMin: e.target.value })
                }
                placeholder="Min"
                required
              />
              <input
                type="number"
                className="bg-white text-black text-sm 
                    w-[40%]
                    ml-2
                    placeholder-black
                    border-b-4
                    border-b-black
                    outline-none
                    font-bold 
                     p-2.5 
                    transition-all duration-200
                    focus:outline-none
                    "
                onChange={(e) =>
                  setFilter({ ...filter, priceMax: e.target.value })
                }
                placeholder="Max"
                required
              />
            </p>

            <h1 className="w-full pt-10" onClick={applyFilter}>
              <MainCTAButton>{filterButtonText}</MainCTAButton>
            </h1>
          </div>
          <div className="products-div w-full md:w-[80%]  pt-[3.4rem]">
            <p className="text-right w-full mx-auto pb-4 font-semibold">
              <Link to="#">
                Default order <CgChevronDown className="inline-block" />
              </Link>
            </p>
            <div
              className={`grid ${
                products.length > 0 ? "md:grid-cols-4" : "grid-cols-1"
              }  w-full gap-8`}
            >
              {products.length > 0 ? (
                products.map((product, index) => {
                  return (
                    <SingleProduct
                      product_name_color="text-black"
                      product={product}
                      key={product.product_id}
                    />
                  );
                })
              ) : (
                <h1 class="text-center font-bold p-4">
                  No products found for filter selected. Please try refining
                  your filters
                </h1>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AllProducts;
