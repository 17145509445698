import React from "react";

const AlertBanner = ({ title }) => {
  return (
    <>
      {/* <div className="py-3 px-2 md:px-[8.5rem] w-full text-center bg-yellow-300 text-black">
        <h1 className="font-black text-sm">
          Cyber Monday sales are here! Entire site is 15% off
        </h1>
      </div> */}
    </>
  );
};

export default AlertBanner;
