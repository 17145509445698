import React from "react";
import MainCTAButton from "../components/MainCTAButton";
import PageHeading from "../components/PageHeading";
// import image from "../images/p1.jpg";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { InfinitySpin } from "react-loader-spinner";
import { useCart } from "react-use-cart";
import {
  hasDiscount,
  discountValue,
  discountedPriceValue,
} from "../utils/utils";

const ProductDetail = () => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [quantityBeingOrdered, setquantityBeingOrdered] = useState(1);
  const { productId } = useParams();
  const { addItem } = useCart();

  function handleAddItemToCart() {
    if (quantityBeingOrdered > product.quantity_available) {
      setquantityBeingOrdered(1);
    } else {
      addItem(product, quantityBeingOrdered);
    }
  }

  useEffect(() => {
    //
    window.scrollTo(0, 0);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/get-product-detail-by-id?business_secrete=${process.env.REACT_APP_BUSINESS_SECRETE}
        &product_id=${productId}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
          },
        }
      )
      .then((data) => {
        let product_obj = data.data.product;
        //react-use-cart requires a price field
        setProduct({
          ...product_obj,
          price: hasDiscount(product)
            ? discountedPriceValue(product)
            : product_obj.unit_selling_price_without_currency,
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [productId, product]);

  if (loading) {
    return (
      <>
        <PageHeading title="Loading item..." />
        <div className="product-detail-div flex gap-20 w-4/5 mx-auto mb-96 h-[20rem] justify-center items-center">
          <InfinitySpin width="200" color="#000" />
        </div>
      </>
    );
  } else {
    return (
      <>
        <PageHeading title={product.product_name} />
        <div className="product-detail-div md:flex gap-20 w-4/5 mx-auto mb-56">
          <div className="left-div md:w-[60%]">
            <img
              src={product.product_internal_image}
              className="aspect-square rounded-lg transition-all duration-500
                  "
              alt={product.product_name}
            />
          </div>
          <div className="right-div md:w-[50%] relative">
            {hasDiscount(product) ? (
              <span className="bg-yellow-300 p-1 inline-block text-black text-sm font-bold rounded-lg z-10">
                {discountValue(product)} off!
              </span>
            ) : (
              ""
            )}
            <h1 className="font-bold text-xl mt-5">
              {hasDiscount(product) ? (
                <>
                  <span className="line-through text-sm">
                    {product.unit_selling_price}
                  </span>
                  <span className="pl-2">
                    GH₵
                    {discountedPriceValue(product)}
                  </span>
                </>
              ) : (
                <> {product.unit_selling_price}</>
              )}
            </h1>
            {/* <p className="font-semibold pt-3">{product.unit_selling_price}</p> */}
            <p
              className="pt-3"
              dangerouslySetInnerHTML={{ __html: product.product_description }}
            ></p>
            {/* <p className="font-semibold pt-3">Quantity:</p> */}

            {product.product_type === "granular" &&
            product.quantity_available <= 0 ? (
              <>
                <p className="pt-10 font-semibold text-red-700">
                  Item out of stock.
                </p>
                <p className="pt-2">
                  <Link to="/all-products">
                    <MainCTAButton wdth="w-full">
                      Back To All Products
                    </MainCTAButton>
                  </Link>
                </p>
              </>
            ) : (
              <>
                <input
                  min="1"
                  value={quantityBeingOrdered}
                  type="number"
                  id="first_name"
                  className="bg-white text-black text-sm 
                  placeholder-black
                  border-b-4
                  border-b-black
                  outline-none
                  font-bold 
                  block w-1/2 p-2.5 
                  transition-all duration-200
                  focus:outline-none
                  mt-5
                  "
                  onChange={(e) => setquantityBeingOrdered(e.target.value)}
                  placeholder="Enter quantity to order"
                  required
                />
                <p className="pt-10 font-semibold text-green-600">
                  Item in stock. Order while stocks last
                </p>
                <p className="pt-2" onClick={handleAddItemToCart}>
                  <MainCTAButton wdth="w-full">Add to cart</MainCTAButton>
                </p>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default ProductDetail;
