import React from "react";
import { Link } from "react-router-dom";
import MainCTAButton from "../components/MainCTAButton";
import PageHeading from "../components/PageHeading";
// import p1 from "../images/p1.jpg";
import { useCart } from "react-use-cart";

const Cart = () => {
  const { isEmpty, items, cartTotal, updateItemQuantity, removeItem } =
    useCart();

  if (isEmpty)
    return (
      <>
        <div className="w-4/5  mx-auto md:flex justify-center items-center mb-96 flex-col h-56">
          <PageHeading title="🤔 Mmmm. Your Cart Is Currently Empty" />
          <div className="cart-div w-full mx-auto">
            <h1 className="mx-auto">
              <Link to="/all-products">
                <MainCTAButton wdth="w-full">
                  Please shop our products catalogue
                </MainCTAButton>
              </Link>
            </h1>
          </div>
        </div>
      </>
    );
  else {
    return (
      <>
        <PageHeading title="Your Cart" />
        <div className="cart-div w-4/5  mx-auto mb-60">
          {/* cart item */}
          {items.map((item) => {
            return (
              <div
                className="cart-item w-full gap-5 flex content-between mb-14"
                key={item.id}
              >
                <div className="left-div md:w-[10%] relative">
                  <img
                    src={item.product_internal_image}
                    className="aspect-square rounded-lg transition-all duration-500 group-hover:scale-125 group-hover:rotate-6 group-hover:cursor-pointer"
                    alt={item.product_name}
                  />
                  <span className="inline-block top-[-10px] right-[-10px] absolute py-[5px] px-[15px] bg-black rounded-full text-white font-semibold">
                    {item.quantity}
                  </span>
                </div>
                <div className="middle-div mr-auto">
                  <h5 className="font-bold text-xl pt-2">
                    {item.product_name}
                  </h5>
                  <p>Per unit: GHC{item.price}</p>
                  <p className="mt-2">
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity - 1)
                      }
                      className="inline-block bg-zinc-400 text-white rounded-full py-[5px] px-[15px] 
                    hover:bg-red-500  mr-2
                    active:bg-red-600 font-semibold"
                    >
                      -
                    </button>
                    <button
                      onClick={() =>
                        updateItemQuantity(item.id, item.quantity + 1)
                      }
                      className="inline-block bg-slate-600 text-white rounded-full py-[5px] px-[15px] 
                    hover:bg-green-600
                    active:bg-green-700 font-semibold
                   
                    "
                    >
                      +
                    </button>{" "}
                  </p>
                </div>
                <div className="right-div">
                  <h5 className="font-bold text-xl pt-2">
                    GHC{item.quantity * item.price}
                  </h5>
                  <h5
                    onClick={() => removeItem(item.id)}
                    className="text-lg pt-2 hover:cursor-pointer hover:text-gray-500 active:text-black"
                  >
                    Remove
                  </h5>
                </div>
              </div>
            );
          })}

          {/* totalling */}
          <div className="sub-total w-full border-b-black border-b-2 flex content-between pb-2 mb-5">
            <h5 className="inline-block text-xl mr-auto">Sub Total</h5>
            <h5 className="font-bold text-xl inline-block">GHC{cartTotal}</h5>
          </div>
          <div className="total w-full flex content-between pb-2 mb-20">
            <h5 className="inline-block text-xl mr-auto font-semibold">
              Total
            </h5>
            <h5 className="font-bold text-xl inline-block">GHC{cartTotal}</h5>
          </div>
          <div className="w-full text-right">
            <Link to="/checkout">
              <MainCTAButton>Proceed to checkout</MainCTAButton>
            </Link>
          </div>
        </div>
      </>
    );
  }
};

export default Cart;
