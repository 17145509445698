import React, { useEffect } from "react";
import PageHeading from "../components/PageHeading";
import aboutUsImage from "../images/about-us.png";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading title="About us" />
      <div className="about-us-content-div md:flex gap-10 w-4/5 mx-auto  mb-20 pt-5">
        <div className="right-div w-full md:hidden">
          <img
            src={aboutUsImage}
            className="rounded-full md:mt-[-5em] mx-auto"
            alt="About Nouveau Riche"
          />
        </div>
        <div className="left-div mt-10 md:mt-0 md:w-[60%]  md:pl-20 md:pr-5 md:py-5 font-semibold">
          <p>
            Nouveau Riche Candeleria (also known as NRC) aims to bring a “Rich
            New Light” into your life. Founded in 2021, Nouveau Riche operates
            as a Jatoak LTD Company and is the first and only full cannabidiol
            (CBD) retailer in Accra, Ghana. Although Cannabidiol (CBD) comes
            from the Hemp Plant, it does not cause a high and is not addictive.
            Rather, it is often used to treat health conditions such as pain,
            inflammation, migraines, arthritis and pain, depression and anxiety.
          </p>
          <p className="pt-5">
            Nouveau Riche challenges individuals to consider alternative means
            to managing overall wellness and improve health outcomes. Through
            the regular use of cannabidiol (CBD), organic and plant based
            products, Nouveau Riche advances total body awakening through the
            promotion of a sound mind, spiritual consciousness and renewed
            health. Stop by our retail store in East Legon or shop our products
            online, and embrace a new way to managing your health.
          </p>
        </div>
        <div className="right-div hidden md:block w-[40%]">
          <img
            src={aboutUsImage}
            className="rounded-full mt-[-5em] mx-auto"
            alt="About Nouveau Riche"
          />
        </div>
      </div>
    </>
  );
};

export default Aboutus;
