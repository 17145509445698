import React, { useEffect, useState } from "react";
import MainCTAButton from "../components/MainCTAButton";
import PageHeading from "../components/PageHeading";
import { useCart } from "react-use-cart";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";

const Checkout = () => {
  const { isEmpty, items, cartTotal, emptyCart } = useCart();
  const [orderInformation, setorderInformation] = useState({
    name: "",
    location: "N/A",
    address: "",
    phone: "",
    orderNotes: "",
  });

  const [checkoutOption, setCheckoutOption] = useState("pay_upfront");

  let navigate = useNavigate();

  function generateRandomString(length) {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charsetLength = charset.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charsetLength);
      result += charset.charAt(randomIndex);
    }

    return result;
  }

  function determineCheckoutOption() {
    if (checkoutOption === "pay_upfront") {
      initializePayment(onSuccess, onClose);
    } else {
      placeOrder();
    }
  }

  function placeOrder() {
    let formData = {
      customer_name: orderInformation.name,
      customer_phone: orderInformation.phone,
      customer_location: orderInformation.location,
      customer_address: orderInformation.address,
      order_notes: orderInformation.orderNotes,
      cart_data: items,
      business_secrete: process.env.REACT_APP_BUSINESS_SECRETE,
    };
    //console.log(formData);
    const toastId = toast.loading(
      "Just a minute. We are processing your order"
    );
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/checkout`, formData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
        },
      })
      .then((data) => {
        toast.dismiss(toastId);
        toast.success(
          "Thank you so much for doing business with us. Your order has been placed successfully. Our team will get back to you in no time.",
          {
            duration: 5000,
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#008080",
              color: "#fff",
            },
          }
        );
        emptyCart();
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(toastId);
        toast.error("There was an error with your order. Please try again");
      });
  }

  /**paystack */
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const onSuccess = (reference) => {
    // //Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    placeOrder();
  };

  // //const checkProgress = (progress) => {
  //   console.log(progress);
  // };

  const paymentConfig = {
    reference: new Date().getTime() + "",
    email: generateRandomString(5) + "@gmail.com",
    amount: cartTotal * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    currency: "GHS",
  };

  const initializePayment = usePaystackPayment(paymentConfig);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading title="Checkout" />
      <div className="about-us-content-div flex flex-col gap-10 w-4/5 mx-auto  mb-20">
        <div className="left-div md:w-[60%]  pl-5 pr-5 py-5 font-semibold">
          <h5 className="font-bold text-2xl mb-5">
            Contact & Address Information
          </h5>

          <input
            type="text"
            id="name"
            className="bg-white text-black text-sm mt-5
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Your name"
            required
            onChange={(e) =>
              setorderInformation({ ...orderInformation, name: e.target.value })
            }
          />
          {/* <input
            type="text"
            id="location"
            className="bg-white text-black text-sm mt-10
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Location"
            required
            onChange={(e) =>
              setorderInformation({
                ...orderInformation,
                location: e.target.value,
              })
            }
          /> */}
          <input
            type="text"
            id="address"
            className="bg-white text-black text-sm mt-10
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Address, City, Country"
            required
            onChange={(e) =>
              setorderInformation({
                ...orderInformation,
                address: e.target.value,
              })
            }
          />
          <input
            type="text"
            id="phone"
            className="bg-white text-black text-sm mt-10
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-[50%] p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Phone/Whatsapp number"
            required
            onChange={(e) =>
              setorderInformation({
                ...orderInformation,
                phone: e.target.value,
              })
            }
          />

          <div className="flex items-center space-x-4 mt-10">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                className="border border-black cursor-pointer scale-150 "
                name="checkout-option"
                value="pay_upfront"
                onChange={(e) => setCheckoutOption("pay_upfront")}
                checked={checkoutOption === "pay_upfront"}
              />
              <span className="text-black cursor-pointer text-sm font-bold">
                Pay Now
              </span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="radio"
                className="border border-black cursor-pointer scale-150"
                name="checkout-option"
                value="pay_on_delivery"
                onChange={(e) => setCheckoutOption("pay_on_delivery")}
                checked={checkoutOption === "pay_on_delivery"}
              />
              <span className="text-black cursor-pointer text-sm font-bold">
                Pay On Delivery
              </span>
            </label>
          </div>

          <textarea
            type="text"
            id="first_name"
            className="bg-white text-black text-sm mt-10
                placeholder-black
                border-4
                border-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Additional order notes"
            required
            onChange={(e) =>
              setorderInformation({
                ...orderInformation,
                orderNotes: e.target.value,
              })
            }
          />
        </div>

        {isEmpty ? (
          <div className="w-full text-right mt-10">
            <MainCTAButton>
              Can't place order now (Your cart is empty)
            </MainCTAButton>
          </div>
        ) : (
          <div className="w-full text-right md:mt-100">
            {/* <span onClick={payWithSeerbit}> */}
            <span onClick={determineCheckoutOption}>
              <MainCTAButton>Place order</MainCTAButton>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Checkout;
