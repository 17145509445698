import React from "react";
import DoubleEdgedArrowBlack from "./DoubleEdgedArrowBlack";
import AlertBanner from "./AlertBanner";

const PageHeading = ({ title }) => {
  return (
    <>
      <DoubleEdgedArrowBlack />
      <AlertBanner />
      <div className="py-10 px-10 md:px-[8.5rem]">
        <h1 className="font-black text-2xl">{title}</h1>
      </div>
    </>
  );
};

export default PageHeading;
