import React from "react";

const SecondaryCTAButton = ({ children }) => {
  return (
    <button
      className="
        text-base
        font-semibold
        border-2
        border-solid
        border-white
        bg-transparent
        text-white py-2 px-10 
        rounded
        hover:bg-white
        hover:text-black
        transition-all duration-200
        active:bg-slate-700
        active:text-white
        "
    >
      {children}
    </button>
  );
};

export default SecondaryCTAButton;
