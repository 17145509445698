import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

const DoubleEdgedArrowBlack = () => {
  return (
    <>
      <div
        className="w-[38px] h-[380px] absolute
      top-[35%] left-[35px] hidden md:flex gap-1
      "
      >
        <div className={`arrow-div arrow-div-black bg-black`}></div>

        <div
          className="social-media-links w-[60%] h-full 
        flex items-center justify-center flex-col"
        >
          <a
            href="https://instagram.com/nouveaurichegh_?igshid=YmMyMTA2M2Y="
            className="inline-block p-2"
          >
            <FaInstagram />
          </a>
          <a
            href="https://twitter.com/nouveaurichegh?s=11&t=5K-H94PL-1Zgr6DIqHTlpg"
            className="inline-block p-2"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.facebook.com/Nouveaurichegh?mibextid=LQQJ4d"
            className="inline-block p-2"
          >
            <FaFacebookF />
          </a>
        </div>
      </div>
    </>
  );
};

export default DoubleEdgedArrowBlack;
