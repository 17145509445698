import { Link } from "react-router-dom";
import React from "react";
import MainCTAButton from "../components/MainCTAButton";
import SecondaryCTAButton from "../components/SecondaryCTAButton";
// import BrownButton from "../components/BrownButton";
// import CategoryOverlay from "../components/CategoryOverlay";
import { FiArrowDownCircle } from "react-icons/fi";
// import { CgArrowLongRight } from "react-icons/cg";
// import SingleProduct from "../components/SingleProduct";
// import p1 from "../images/p1.jpg";
// import p2 from "../images/p2.jpg";
// import p3 from "../images/p3.jpg";
import landingPageBackgroundImage from "../images/bg.jpg";
// import categoryEnergyImage from "../images/energy.png";
// import categoryImmunityImage from "../images/immunity.png";
// import categoryPainreliefImage from "../images/painrelief.png";
// import categorySleepImage from "../images/sleep.png";
// import fourRandomProductsBackgroundImage from "../images/four_random_products_background_image.png";
// import weedLeft from "../images/weed_left.png";
// import weedRight from "../images/weed_right.png";
// import tinctureDrops from "../images/tincturedrops.png";
import DoubleEdgedArrow from "../components/DoubleEdgedArrow";
import AlertBanner from "../components/AlertBanner";
// import axios from "axios";
// import { useEffect, useState } from "react";

const Home = () => {
  //const images = [p1, p2, p3, p1];
  // const [products, setProducts] = useState([]);

  //const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/get-random-products?business_secrete=${process.env.REACT_APP_BUSINESS_SECRETE}&limit=4`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}`,
  //         },
  //       }
  //     )
  //     .then((data) => setProducts(data.data.products))
  //     .catch((error) => console.log(error));
  // }, []);

  return (
    <>
      <DoubleEdgedArrow />
      {/* // Hero landing section */}
      <AlertBanner />
      <section
        style={{ backgroundImage: `url(${landingPageBackgroundImage})` }}
        className="bg-cover bg-center flex flex-wrap items-center justify-end p-10 md:p-32 md:pt-44 md:pb-11"
      >
        <div className="text-white w-full font-semibold ita text-center italic  md:w-auto absolute top-[15%] md:top-[23%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
          <h2>Nouveau Riche Candelaria</h2>
        </div>
        <div className="text-white w-full md:w-auto">
          <h4 className="font-bold text-4xl mt-10 md:mt-0">
            All Natural Aromatherapy, CBD <br /> & Plant Based Products
          </h4>
          <p className="text-2xl mt-8">For total body wellness.</p>
          <Link to="/all-products" className="mt-8 inline-block">
            <MainCTAButton>Shop Products</MainCTAButton>
          </Link>
          <Link to="/contact-us" className="mt-8 inline-block sm:ml-7 xs:ml-0">
            <SecondaryCTAButton>
              Schedule an in-store consultation
            </SecondaryCTAButton>
          </Link>
        </div>
        {/* mt-96 */}
        <div className="w-full flex justify-center mt-40 md:mt-56">
          <FiArrowDownCircle className="text-white text-5xl" />
        </div>
      </section>
      {/* //categories */}
      {/* <section className="bg-black flex flex-col justify-center items-center p-36 pb-20">
        <h5
          className="uppercase text-orange-300 font-bold tracking-widest text-2xl
        after:content-[''] after:w-20 after:bg-red-300 after:h-1 after:block
        after:mx-auto after:mt-2
        "
        >
          Categories
        </h5>
        <section className="columns-2 mt-10 w-11/12 gap-16 h-96">
          <div
            style={{ backgroundImage: `url(${categoryEnergyImage})` }}
            className="outer-div bg-cover bg-center h-full rounded-lg cursor-pointer relative hover:bg"
          >
            <Link to="/all-products?child_category=45">
              <CategoryOverlay
                category_name="Energy"
                category_description="CBD products to build/ sustain your energy and natural focus"
              />
            </Link>
          </div>
          <div className="outer-div h-full columns-2 gap-10">
            <div className="inner-left-div h-full">
              <div
                className="w-full h-[45%] bg-cover bg-center rounded-lg cursor-pointer relative"
                style={{ backgroundImage: `url(${categorySleepImage})` }}
              >
                <Link to="/all-products?child_category=39">
                  <CategoryOverlay
                    category_name="Sleep"
                    category_description="CBD products to improve your quality of sleep and give you a wonderful night’s rest"
                  />
                </Link>
              </div>
              <div className="w-full h-[10%]"></div>
              <div
                className="w-full h-[45%] bg-cover bg-center rounded-lg cursor-pointer relative"
                style={{ backgroundImage: `url(${categoryPainreliefImage})` }}
              >
                <Link to="/all-products?child_category=38">
                  <CategoryOverlay
                    category_name="Pain Relief"
                    category_description="CBD products targeted to alleviate pain"
                  />
                </Link>
              </div>
            </div>
            <div
              className="inner-right-div bg-cover bg-center h-full rounded-lg cursor-pointer relative"
              style={{ backgroundImage: `url(${categoryImmunityImage}` }}
            >
              <Link to="/all-products?child_category=40">
                <CategoryOverlay
                  category_name="Immunity"
                  category_description="BD products to give your body the protection it needs to keep you feeling your best"
                />
              </Link>
            </div>
          </div>
        </section>

        <Link to="/all-products" className="mt-20 block">
          <MainCTAButton>View Products</MainCTAButton>
        </Link>
      </section> */}
      {/*4 random products*/}
      {/* <section
        style={{ backgroundImage: `url(${fourRandomProductsBackgroundImage})` }}
        className="bg-cover bg-center py-24 relative"
      >
        <h5
          className="uppercase text-center text-orange-300 font-bold tracking-widest text-2xl
        after:content-[''] after:w-20 after:bg-red-300 after:h-1 after:block
        after:mx-auto after:mt-2
        "
        >
          Products
        </h5>
        <p className="text-right text-white w-4/6  mx-auto mt-10">
          <Link to="/all-products">
            View all <CgArrowLongRight className="inline-block" />
          </Link>
        </p>
        <div className="w-4/6 mx-auto mt-3 grid grid-cols-4 gap-8">
          {products.map((product, index) => {
            return (
              <SingleProduct
                product_name_color="text-white"
                product={product}
                key={product.product_id}
              />
            );
          })}
        </div>
        <img
          src={weedLeft}
          alt="Weed plant left"
          className="absolute top-[-28%] left-0"
        />
        <img
          src={weedRight}
          alt="Weed plant right"
          className="absolute top-[-28%] right-0"
        />
      </section> */}

      {/**Orange white gradient section */}
      {/* <section className="bg-gradient-to-b from-[#DA8F25] via-white to-white py-24">
        <div className="before-footer-div w-4/6 mt-4 mx-auto columns-2">
          <div className="left-div">
            <img
              src={tinctureDrops}
              className="aspect-square"
              alt="Premium broad spectrum tincture"
            />
          </div>
          <div className="right-div tracking-widest text-[#593809]">
            <p className="font-semibold pt-5">NEW PRODUCT</p>
            <p className="font-bold text-2xl pt-1">Premium Broad Spectrum</p>
            <p className="pt-8">
              <ul className="font-bold list-disc pl-9">
                <li>THC Free</li>
                <li>GMO Free</li>
                <li>Gluton Free</li>
              </ul>
            </p>
            <p className="">
              <Link to="/product-detail/298574" className="mt-10 block">
                <BrownButton>Buy Now</BrownButton>
              </Link>
            </p>
          </div>
        </div>
        <div className="pt-28 pb-5  w-2/5 mx-auto mt-[-3em]">
          <h5 className="text-center font-bold text-3xl mb-16">
            Subscribe and get <br /> 20% off your first purchase
          </h5>
          <div>
            <form className="flex border border-l-transparent border-r-transparent border-t-transparent border-b-black border-b-[2px]">
              <input
                type="text"
                id="first_name"
                className="bg-white text-black text-sm  
                placeholder-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
                placeholder="Email"
                required
              />
              <p
                className="pt-[0.7rem] inline-block font-bold
              hover:cursor-pointer
              hover:opacity-30
              transition ease-in-out
              "
              >
                Submit
              </p>
            </form>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;
