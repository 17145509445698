import React from "react";

const MainCTAButton = ({ children, wdth = "auto" }) => {
  return (
    <button
      className={`
      ${wdth}
      text-base
      font-semibold
      border-2
      border-solid
      border-yellow-600
      bg-yellow-600
      text-white py-2 px-10 
      rounded
      hover:bg-orange-300
      hover:border-orange-300
      transition-all duration-200
      active:bg-orange-500
      active:border-orange-500
  `}
    >
      {children}
    </button>
  );
};

export default MainCTAButton;
