import React from "react";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  hasDiscount,
  discountValue,
  discountedPriceValue,
} from "../utils/utils";

const SingleProduct = ({ product, product_name_color }) => {
  return (
    <>
      <div className={`product-div mb-6`}>
        <div className="group inner-product-div w-full  relative overflow-hidden rounded-lg ">
          {hasDiscount(product) ? (
            <span className="bg-yellow-300 block p-1 absolute top-3 left-3 text-black text-xs font-bold rounded-lg z-10">
              {discountValue(product)} off!
            </span>
          ) : (
            ""
          )}
          <img
            src={product.product_internal_image}
            className="aspect-square rounded-lg transition-all duration-500
                group-hover:scale-125 group-hover:rotate-6 group-hover:cursor-pointer
                "
            alt={product.product_name}
          />
          <Link to={`/product-detail/${product.product_id}`}>
            <div
              className="overlay w-full h-full 
                          absolute top-0 left-0 
                          bg-black bg-opacity-60 hidden justify-center items-center
                          transition-all duration-500
                          group-hover:flex  group-hover:cursor-pointer
                          "
            >
              <AiFillEye className="text-white text-4xl" />
            </div>
          </Link>
        </div>
        <p className={`${product_name_color} font-bold mt-3`}>
          {product.product_name}
        </p>

        <p className="text-slate-400">
          {hasDiscount(product) ? (
            <>
              <span className="line-through text-sm">
                {product.unit_selling_price}
              </span>
              <span className="pl-2">
                GH₵
                {discountedPriceValue(product)}
              </span>
            </>
          ) : (
            <> {product.unit_selling_price}</>
          )}
        </p>
      </div>
    </>
  );
};

export default SingleProduct;
