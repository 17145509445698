import React from "react";
import { useEffect } from "react";
// import MainCTAButton from "../components/MainCTAButton";
import PageHeading from "../components/PageHeading";
import { InlineWidget } from "react-calendly";

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading title="Contact Us" />
      <div className="contact-us-div md:flex md:gap-32 md:w-[75%] mx-auto  md:pt-5 md:p-0 p-10">
        <div className="left-div md:w-[35%]  uppercase font-semibold text-sm">
          <p className="p-1">Telephone: 030 252 9837</p>
          <p className="p-1">Mobile: 059 861 3855</p>
          <p className="p-1">Email: info@thenouveauriche.co</p>
          <p className="p-1">Location: Dt plaza-shop 16</p>
          <p className="p-1">10 boundary road, shiashe, east legon</p>
        </div>
        <div className="right-div md:w-[65%] mt-10 md:mt-0">
          <h5 className="font-bold text-2xl md:text-center">
            How can we improve your health?
          </h5>
          <p className="md:text-center">
            We are looking forward to hearing from you.
          </p>
          <p className="md:text-center mb-11">Get in touch with us</p>

          {/* <input
            type="text"
            id="first_name"
            className="bg-white text-black text-sm 
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Name"
            required
          />

          <input
            type="text"
            id="first_name"
            className="bg-white text-black text-sm mt-5
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-full p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Area of concern"
            required
          />

          <input
            type="datetime-local"
            id="first_name"
            className="bg-white text-black text-sm mt-5 mb-20
                placeholder-black
                border-b-4
                border-b-black
                outline-none
                font-bold 
                block w-1/2 p-2.5 
                transition-all duration-200
                focus:outline-none
                "
            placeholder="Appointment time"
            required
          />

          <div className="submit-button w-1/2 text-right">
            <MainCTAButton>Submit</MainCTAButton>
          </div> */}
        </div>
      </div>
      <div className="schedule-div w-[80%] mx-auto mb-40 bg-slate-50 md:mt-10 rounded-md">
        <InlineWidget url="https://calendly.com/jt--110/30min" />
      </div>
    </>
  );
};

export default Contactus;
